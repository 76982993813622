input[type=text] {
    width: 9em;
} 

button {
    width: 9em;
} 

.controller {
    display: grid;
    grid-template-columns: repeat(3, minmax(375px, 1fr)); /* see notes below */
}

.cityCards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr)); /* see notes below */
}

.card {
    display: grid; 
    grid-auto-rows: 1.5em; 
    column-gap: 10px;

    border-radius: 10px;
    padding: 5px;
    margin: 5px;
}

.two-column {
    grid-template-columns: repeat(2, 1fr); 
} 

.three-column {
    grid-template-columns: repeat(3, 1fr); 
} 

.four-column {
    grid-template-columns: repeat(4, 1fr); 
} 


.controllerCard {
    background-color: turquoise;
}


.cityCard {
    background-color: deepskyblue;
}


.wideColumnCentered { 
    grid-column: span 2;
    justify-self: center;
}

.centered {
    justify-self: center;
}

.cityCardCell {
    display: table-cell;
}

.cityCardHeader {
    display: table-footer-group;
    text-align: center;
}

.infoMessage {
    color: white;
}